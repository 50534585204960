/** @jsx jsx */
import { jsx } from '@balance-web/core';
import { Stack } from '@balance-web/stack';
import { Heading } from '@balance-web/heading';
import { Text } from '@balance-web/text';
import { DeprecatedButton } from '@balance-web/button-deprecated';

export type NotFoundProps = {
  title?: string;
  onHomeButtonClick?: () => void;
};
export const NotFound: React.FC<NotFoundProps> = ({
  title = 'Page not found',
  onHomeButtonClick = () => {},
}) => {
  return (
    <Stack align="center" gap="xxlarge" css={{ marginTop: 100 }}>
      <Stack gap="large">
        <Heading align="center" level="4">
          {title}
        </Heading>
        <Text
          color="muted"
          size="small"
          leading="loose"
          weight="medium"
          align="center"
        >
          We can't find the page you're looking for. It might be an old link{' '}
          <br />
          or may be it moved.
        </Text>
      </Stack>
      <DeprecatedButton
        id="404-go-home"
        label="Go to Homepage"
        onClick={onHomeButtonClick}
      />
    </Stack>
  );
};

import React from 'react';
import { useRouter } from 'next/router';

import { AppLayoutContainer } from '../components/app/AppLayout';
import { NotFound } from '../components/app/NotFound';

const NotFoundRoute = () => {
  const router = useRouter();
  const handleHomeButtonClick = () => router.push('/');
  return (
    <AppLayoutContainer>
      <NotFound onHomeButtonClick={handleHomeButtonClick} />
    </AppLayoutContainer>
  );
};

export default NotFoundRoute;
